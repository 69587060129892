﻿body {
    /* Hide the Grid header and pager during export */
    .k-pdf-export .k-grid-toolbar,
    .k-pdf-export .k-pager-wrap {
        display: none;
    }

    .k-grid tr.k-state-selected:hover td,
    .k-grid tr:hover .k-state-selected,
    .k-grid tr.k-state-selected {
        background-color: #808080;

        > td {
            border-color: #909090;
        }
    }

    .k-grid {
        .k-selectable {
            tr:hover {
                cursor: pointer;
            }
        }

        .k-header {
            &.k-grid-toolbar {
                //background-color:#eee;
                //border-color:#eee;
            }
        }
    }

    .k-pager-numbers .k-state-selected {
        border-radius: 0;
    }

    .k-grid-norecords-template {
        border: 0;
    }

    #ClaimReserveHistory {
        $TextSize: 12px;

        .k-group-cell {
            display: none;
        }

        th {
            font-size: $TextSize + 1;
            /*padding: 0px 0px 0px 0px;*/
            a {
                &.k-link {
                    margin: auto auto;
                    height: 100%;
                }
            }
        }

        p {
            &.k-reset {
                font-size: $TextSize +1 !important;
            }
        }

        td[role="gridcell"] {
            word-break: break-all !important;
            word-wrap: break-word !important;
            font-size: $TextSize;
        }

        tr {
            &.k-footer-template {
                td {
                    font-size: $TextSize + 1;
                }
            }
        }
    }
}
